.story-page-video {
  background: rgba(255, 255, 255, 0.1);
  margin: 0 auto;
  width: 100%;
  max-width: 600px;
  overflow: hidden;
}

.audio::-webkit-media-controls-panel {
  background-color: #161618;
}

.audio::-webkit-media-controls-volume-slider-container {
  background-color: #1FB2B0;
}

.audio::-webkit-media-controls-volume-slider {
  background-color: #1FB2B0;
}

.audio::-webkit-media-controls-timeline {
  background-color: #1FB2B0;
}

.audio-overlay {
  margin-top: -32px;
  position: relative;
  max-width: 600px;
}

.audio-overlay::-webkit-media-controls-panel {
  background: rgba(000, 000, 000, 0.5);
}

.tellAStory>video {
  border-radius: 10px;
}